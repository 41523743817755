/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-filename-extension */

import React from "react";

import BrandIcon from "./BrandIcon";
import Button from "../elements/Button";

export default function Footer() {
  return (
    <div className="bg-gray-50 border-t border-gray-200 pb-6">
      <div className="container flex-col mx-auto ">
        <div className="flex flex-col sm:flex-row mt-8 justify-center">
          <div className="w-1/3 flex-col ml-16 mr-8">
            <BrandIcon />
            <p className="w-full text-lg text-gray-400 font-light">
              Turning Ideas into <br />
              Digital Realities
            </p>
          </div>
          <div className="w-1/3 mt-0 ml-16 mr-0 sm:ml-0 sm:mr-5">
            <h1 className="text-lg text-theme-blue pt-4 pb-2">Office</h1>
            <p className="text-lg text-gray-400 font-light">
              <strong>Office 1:</strong> 3-A Suites, San Francisco, California,
              USA. <br />
              <strong>Office 2:</strong> WAPDA Town, Lahore, Pakistan.
            </p>
          </div>
          <div className="w-1/3 ml-16 sm:ml-0 mt-0">
            <h1 className="text-lg text-theme-blue pt-4 pb-2">Social</h1>
            <Button
              href="https://www.linkedin.com"
              type="link"
              target="_blank"
              className="flex text-lg text-gray-400 font-light hover:underline"
              isExternal
            >
              LinkedIn
            </Button>
            <Button
              href="mailto:ahmad@codeinity.com"
              type="link"
              className="flex text-lg text-gray-400 font-light hover:underline mt-2"
              isExternal
            >
              Email
            </Button>
          </div>
        </div>
        <div className="flex-col text-center mt-7">
          <p className="text-lg text-gray-400 font-light">
            Copyright 2021 - All rights reserved - Codeinity Solutions
          </p>
        </div>
      </div>
    </div>
  );
}
