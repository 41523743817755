/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-filename-extension */

import React from "react";

import Button from "../elements/Button";

export default function BrandIcon() {
  return (
    <Button className="" type="link" href="/">
      {/* <p className="text-theme-blue text-4xl font-medium ">
        Code
        <span className="text-theme-purple">inity</span>
      </p> */}
      <img
        src={process.env.PUBLIC_URL + "/logo-lt.png"}
        alt="logo"
        style={{ height: "2.4rem" }}
      />
    </Button>
  );
}
